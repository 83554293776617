import { IExampleData } from './mockTypes';
import data from './data/currency.json';

const prospects = data.usersProspects;

export interface IProspectGET {
  email: string;
  _id: string;
}

export const currencyGET = (
  data: IProspectGET
): [number, { description: string; values: Partial<IExampleData> | Record<string, never> }] => {
  const { email, _id } = data;
  const queryResult = prospects.find(
    (prospect: IProspectGET) => prospect._id === _id && prospect.email === email
  );
  if (queryResult) {
    return [200, { description: 'success', values: queryResult.values }];
  }
  // throw Error
  return [404, { description: 'prospectError', values: {} }];
};
