import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';
import { currencyGET } from './currency.mock';

export const isMockEnabled = (): boolean => {
  return process.env.REACT_APP_MOCK_ENABLED === 'true' || process.env.NODE_ENV === 'test';
};

export const initializeAxiosMockAdapter = (instance: AxiosInstance): void => {
  const mock = new MockAdapter(instance);
  // Prospect
  mock.onGet('/currency/list').reply((config) => currencyGET(config.params));
};
